import React from 'react';
import '@fontsource/plus-jakarta-sans';

import '@/styles/index.scss';

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation !== null && location.hash === '') {
    window.setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 100); // Special delay for full load page
  }
};
